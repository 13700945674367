import React, { useState, useEffect } from "react";
import axios from "axios";

const ApiData = () => {
  const [emails, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Replace with your API URL
    const fetchData = async () => {
      try {
        const response = await axios.get("http://datamail.ltd:3001/email/get-emails");
        setData(Array.isArray(response.data.emails) ? response.data.emails : []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div className="text-center mt-4">Loading emails...</div>;
  if (error) return <div className="alert alert-danger mt-4">Error: {error}</div>;

  return (
    <div className="container mt-5">
      <h4 className="text-center mb-4">Datamail</h4>
      <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Country</th>
            <th>Password (#Hash)</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {emails?.length > 0 ? (
            emails.map((email) => (
              <tr key={email.id}>
                <td>{email.id}</td>
                <td>{email.email}</td>
                <td>{email.country}</td>
                <td>{email.password}</td>
                <td>{new Date(email.createdAt).toLocaleString()}</td>
                <td>{new Date(email.updatedAt).toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No emails found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default ApiData;